import { useFormContext, useWatch } from "react-hook-form"
import { friendlyNumberAndBeyond, moneyAndBeyond } from "~/util/numbers"
import { SocialMediaFieldLabel } from "./SocialMediaFieldLabel"
import {
  ENG_RATE_MAX,
  ENG_RATE_MIN,
  FOLLOWERS_MAX,
  FOLLOWERS_MIN,
  FOLLOWERS_STEP,
  PRICING_CENTS_MAX,
  PRICING_CENTS_MIN,
  PRICING_CENTS_STEP,
  SearchValues,
  VIEWS_MAX,
  VIEWS_MIN,
  VIEWS_STEP,
} from "../../schema"

import youtubeIcon from "~/images/icons/youtube.png"
import { RangeSlider } from "~/ui/RangeSlider"
import { SocialMediaFilter } from "./SocialMediaFilter"

export const YoutubeFilter = () => {
  const form = useFormContext<SearchValues>()

  const enabled = useWatch({
    control: form.control,
    name: "youtubeEnabled",
  })

  const subscribersMin =
    useWatch({
      control: form.control,
      name: "youtubeSubscribersMin",
    }) || FOLLOWERS_MIN

  const subscribersMax =
    useWatch({
      control: form.control,
      name: "youtubeSubscribersMax",
    }) || FOLLOWERS_MAX

  const videosAvgViewsMin =
    useWatch({
      control: form.control,
      name: "youtubeVideosAvgViewsMin",
    }) || VIEWS_MIN

  const videosAvgViewsMax =
    useWatch({
      control: form.control,
      name: "youtubeVideosAvgViewsMax",
    }) || VIEWS_MAX

  const videosEngagementRateMin =
    useWatch({
      control: form.control,
      name: "youtubeVideosEngagementRateMin",
    }) || ENG_RATE_MIN

  const videosEngagementRateMax =
    useWatch({
      control: form.control,
      name: "youtubeVideosEngagementRateMax",
    }) || ENG_RATE_MAX

  const shortsAvgViewsMin =
    useWatch({
      control: form.control,
      name: "youtubeShortsAvgViewsMin",
    }) || VIEWS_MIN

  const shortsAvgViewsMax =
    useWatch({
      control: form.control,
      name: "youtubeShortsAvgViewsMax",
    }) || VIEWS_MAX

  const shortsEngagementRateMin =
    useWatch({
      control: form.control,
      name: "youtubeShortsEngagementRateMin",
    }) || ENG_RATE_MIN

  const shortsEngagementRateMax =
    useWatch({
      control: form.control,
      name: "youtubeShortsEngagementRateMax",
    }) || ENG_RATE_MAX

  const integrationRateMin =
    useWatch({
      control: form.control,
      name: "youtubeIntegrationRateCentsMin",
    }) || PRICING_CENTS_MIN

  const integrationRateMax =
    useWatch({
      control: form.control,
      name: "youtubeIntegrationRateCentsMax",
    }) || PRICING_CENTS_MAX

  const dedicationRateMin =
    useWatch({
      control: form.control,
      name: "youtubeDedicationRateCentsMin",
    }) || PRICING_CENTS_MIN

  const dedicationRateMax =
    useWatch({
      control: form.control,
      name: "youtubeDedicationRateCentsMax",
    }) || PRICING_CENTS_MAX

  const shortsRateMin =
    useWatch({
      control: form.control,
      name: "youtubeShortsRateCentsMin",
    }) || PRICING_CENTS_MIN

  const shortsRateMax =
    useWatch({
      control: form.control,
      name: "youtubeShortsRateCentsMax",
    }) || PRICING_CENTS_MAX

  return (
    <SocialMediaFilter
      enabledFieldName="youtubeEnabled"
      hasRatesFieldName="youtubeHasRates"
      icon={youtubeIcon}
      title="Youtube"
    >
      <div>
        <SocialMediaFieldLabel text="Subscribers" />
        <RangeSlider
          min={FOLLOWERS_MIN}
          max={FOLLOWERS_MAX}
          step={FOLLOWERS_STEP}
          value={[subscribersMin, subscribersMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeSubscribersMin", min)
            form.setValue("youtubeSubscribersMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, FOLLOWERS_MAX, 2)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Videos: Average Views" />
        <RangeSlider
          min={VIEWS_MIN}
          max={VIEWS_MAX}
          step={VIEWS_STEP}
          value={[videosAvgViewsMin, videosAvgViewsMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeVideosAvgViewsMin", min)
            form.setValue("youtubeVideosAvgViewsMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, VIEWS_MAX)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Videos: Engagement Rate" />
        <RangeSlider
          min={ENG_RATE_MIN * 100}
          max={ENG_RATE_MAX * 100}
          step={1}
          value={[videosEngagementRateMin * 100, videosEngagementRateMax * 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeVideosEngagementRateMin", min / 100)
            form.setValue("youtubeVideosEngagementRateMax", max / 100)
          }}
          disabled={!enabled}
          format={(value) => `${value}%`}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Shorts: Average Views" />
        <RangeSlider
          min={VIEWS_MIN}
          max={VIEWS_MAX}
          step={VIEWS_STEP}
          value={[shortsAvgViewsMin, shortsAvgViewsMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeShortsAvgViewsMin", min)
            form.setValue("youtubeShortsAvgViewsMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, VIEWS_MAX)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Shorts: Engagement Rate" />
        <RangeSlider
          min={ENG_RATE_MIN * 100}
          max={ENG_RATE_MAX * 100}
          step={1}
          value={[shortsEngagementRateMin * 100, shortsEngagementRateMax * 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeShortsEngagementRateMin", min / 100)
            form.setValue("youtubeShortsEngagementRateMax", max / 100)
          }}
          disabled={!enabled}
          format={(value) => `${value}%`}
        />
      </div>
      <div />
      <div>
        <SocialMediaFieldLabel text="Integration Rate" />
        <RangeSlider
          min={PRICING_CENTS_MIN / 100}
          max={PRICING_CENTS_MAX / 100}
          step={PRICING_CENTS_STEP / 100}
          value={[integrationRateMin / 100, integrationRateMax / 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeIntegrationRateCentsMin", min * 100)
            form.setValue("youtubeIntegrationRateCentsMax", max * 100)
          }}
          disabled={!enabled}
          format={(n) => moneyAndBeyond(n, PRICING_CENTS_MAX / 100)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Dedication Rate" />
        <RangeSlider
          min={PRICING_CENTS_MIN / 100}
          max={PRICING_CENTS_MAX / 100}
          step={PRICING_CENTS_STEP / 100}
          value={[dedicationRateMin / 100, dedicationRateMax / 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeDedicationRateCentsMin", min * 100)
            form.setValue("youtubeDedicationRateCentsMax", max * 100)
          }}
          disabled={!enabled}
          format={(n) => moneyAndBeyond(n, PRICING_CENTS_MAX / 100)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Shorts Rate" />
        <RangeSlider
          min={PRICING_CENTS_MIN / 100}
          max={PRICING_CENTS_MAX / 100}
          step={PRICING_CENTS_STEP / 100}
          value={[shortsRateMin / 100, shortsRateMax / 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("youtubeShortsRateCentsMin", min * 100)
            form.setValue("youtubeShortsRateCentsMax", max * 100)
          }}
          disabled={!enabled}
          format={(n) => moneyAndBeyond(n, PRICING_CENTS_MAX / 100)}
        />
      </div>
    </SocialMediaFilter>
  )
}
