import { useFormContext, useWatch } from "react-hook-form"
import { friendlyNumberAndBeyond, moneyAndBeyond } from "~/util/numbers"
import { SocialMediaFieldLabel } from "./SocialMediaFieldLabel"
import {
  ENG_RATE_MAX,
  ENG_RATE_MIN,
  FOLLOWERS_MAX,
  FOLLOWERS_MIN,
  FOLLOWERS_STEP,
  PRICING_CENTS_MAX,
  PRICING_CENTS_MIN,
  PRICING_CENTS_STEP,
  SearchValues,
  VIEWS_MAX,
  VIEWS_MIN,
  VIEWS_STEP,
} from "../../schema"

import instagramIcon from "~/images/icons/instagram.png"
import { RangeSlider } from "~/ui/RangeSlider"
import { SocialMediaFilter } from "./SocialMediaFilter"

export const InstagramFilter = () => {
  const form = useFormContext<SearchValues>()

  const enabled = useWatch({
    control: form.control,
    name: "instagramEnabled",
  })

  const followersMin =
    useWatch({
      control: form.control,
      name: "instagramFollowersMin",
    }) || FOLLOWERS_MIN

  const followersMax =
    useWatch({
      control: form.control,
      name: "instagramFollowersMax",
    }) || FOLLOWERS_MAX

  const avgViewsMin =
    useWatch({
      control: form.control,
      name: "instagramAvgViewsMin",
    }) || VIEWS_MIN

  const avgViewsMax =
    useWatch({
      control: form.control,
      name: "instagramAvgViewsMax",
    }) || VIEWS_MAX

  const engagementRateMin =
    useWatch({
      control: form.control,
      name: "instagramEngagementRateMin",
    }) || ENG_RATE_MIN

  const engagementRateMax =
    useWatch({
      control: form.control,
      name: "instagramEngagementRateMax",
    }) || ENG_RATE_MAX

  const reelsRateMin =
    useWatch({
      control: form.control,
      name: "instagramReelsRateCentsMin",
    }) || PRICING_CENTS_MIN

  const reelsRateMax =
    useWatch({
      control: form.control,
      name: "instagramReelsRateCentsMax",
    }) || PRICING_CENTS_MAX

  return (
    <SocialMediaFilter
      enabledFieldName="instagramEnabled"
      hasRatesFieldName="instagramHasRates"
      icon={instagramIcon}
      title="Instagram"
    >
      <div>
        <SocialMediaFieldLabel text="Followers" />
        <RangeSlider
          min={FOLLOWERS_MIN}
          max={FOLLOWERS_MAX}
          step={FOLLOWERS_STEP}
          value={[followersMin, followersMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("instagramFollowersMin", min)
            form.setValue("instagramFollowersMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, FOLLOWERS_MAX, 2)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Average Views" />
        <RangeSlider
          min={VIEWS_MIN}
          max={VIEWS_MAX}
          step={VIEWS_STEP}
          value={[avgViewsMin, avgViewsMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("instagramAvgViewsMin", min)
            form.setValue("instagramAvgViewsMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, VIEWS_MAX)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Engagement Rate" />
        <RangeSlider
          min={ENG_RATE_MIN * 100}
          max={ENG_RATE_MAX * 100}
          step={1}
          value={[engagementRateMin * 100, engagementRateMax * 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("instagramEngagementRateMin", min / 100)
            form.setValue("instagramEngagementRateMax", max / 100)
          }}
          disabled={!enabled}
          format={(value) => `${value}%`}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Reels Rate" />
        <RangeSlider
          min={PRICING_CENTS_MIN / 100}
          max={PRICING_CENTS_MAX / 100}
          step={PRICING_CENTS_STEP / 100}
          value={[reelsRateMin / 100, reelsRateMax / 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("instagramReelsRateCentsMin", min * 100)
            form.setValue("instagramReelsRateCentsMax", max * 100)
          }}
          disabled={!enabled}
          format={(n) => moneyAndBeyond(n, PRICING_CENTS_MAX / 100)}
        />
      </div>
    </SocialMediaFilter>
  )
}
