import { createContext, useContext, useState } from "react"
import { BulkUploadScreen } from "~/screens/influencers/bulk-upload/BulkUploadScreen"
import { NewInfluencerScreen } from "~/screens/influencers/NewInfluencerScreen"
import { EditInfluencerScreen } from "~/screens/influencers/EditInfluencerScreen"
import { ViewInfluencerScreen } from "~/screens/influencers/view-influencer/ViewInfluencerScreen"
import { Tab } from "~/screens/influencers/components/InfluencerTabBar"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/shadcn/ui/alert-dialog"
import { ModalDialog } from "~/ui/ModalDialog"

export enum InfluencerDialogType {
  NewInfluencerManual = "NewInfluencerManual",
  BulkUpload = "BulkUpload",
  ViewInfluencer = "ViewInfluencer",
  EditInfluencer = "EditInfluencer",
}

interface InfluencerActionsContextType {
  openDialogType: InfluencerDialogType | null
  setOpenDialogType: (dialogType: InfluencerDialogType | null) => void
  setActiveInfluencerId: (id: string) => void
}

const InfluencerActionsContext =
  createContext<InfluencerActionsContextType | null>(null)

export const InfluencerActionsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [openDialogType, setOpenDialogType] =
    useState<InfluencerDialogType | null>(null)
  const [isDirty, setIsDirty] = useState(false)
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false)
  const [activeInfluencerId, setActiveInfluencerId] = useState<string | null>(
    null
  )
  const [currentInfluencerTab, setCurrentInfluencerTab] = useState<
    Tab | undefined
  >()

  const value = { openDialogType, setOpenDialogType, setActiveInfluencerId }

  return (
    <InfluencerActionsContext.Provider value={value}>
      {children}

      <ModalDialog
        open={openDialogType === InfluencerDialogType.NewInfluencerManual}
        onClose={() => {
          if (isDirty) {
            setShowUnsavedChangesDialog(true)
          } else {
            setOpenDialogType(null)
          }
        }}
        className="sm:p-0"
      >
        <NewInfluencerScreen
          onClose={(currentTab, reset) => {
            if (reset) {
              setIsDirty(false)
            }

            if (isDirty && !reset) {
              setShowUnsavedChangesDialog(true)
            } else {
              setOpenDialogType(null)
            }
          }}
          onChangeDirty={setIsDirty}
        />
      </ModalDialog>

      <ModalDialog
        open={openDialogType === InfluencerDialogType.BulkUpload}
        onClose={() => setOpenDialogType(null)}
        className="sm:p-0"
      >
        <BulkUploadScreen onClose={() => setOpenDialogType(null)} />
      </ModalDialog>

      <ModalDialog
        open={
          openDialogType === InfluencerDialogType.ViewInfluencer &&
          !!activeInfluencerId
        }
        onClose={() => {
          setCurrentInfluencerTab(undefined)
          setOpenDialogType(null)
        }}
      >
        {activeInfluencerId && (
          <ViewInfluencerScreen
            influencerId={activeInfluencerId}
            actionable
            onEditClick={(currentTab) => {
              setCurrentInfluencerTab(currentTab)
              setOpenDialogType(InfluencerDialogType.EditInfluencer)
            }}
            onDeleteClick={() => setOpenDialogType(null)}
            initialTab={currentInfluencerTab || Tab.SocialMedia}
          />
        )}
      </ModalDialog>

      <ModalDialog
        open={
          openDialogType === InfluencerDialogType.EditInfluencer &&
          !!activeInfluencerId
        }
        onClose={() => {
          if (isDirty) {
            setShowUnsavedChangesDialog(true)
          } else {
            setOpenDialogType(null)
          }
        }}
      >
        {activeInfluencerId && (
          <EditInfluencerScreen
            influencerId={activeInfluencerId}
            onClose={(currentTab, reset) => {
              if (reset) {
                setIsDirty(false)
              }

              if (isDirty && !reset) {
                setShowUnsavedChangesDialog(true)
              } else {
                setCurrentInfluencerTab(currentTab)
                setOpenDialogType(InfluencerDialogType.ViewInfluencer)
              }
            }}
            initialTab={currentInfluencerTab || Tab.PersonalInformation}
            onChangeDirty={setIsDirty}
          />
        )}
      </ModalDialog>

      <AlertDialog
        open={showUnsavedChangesDialog}
        onOpenChange={setShowUnsavedChangesDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
            <AlertDialogDescription>
              You have unsaved changes. Are you sure you want to cancel?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>No, continue editing</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setShowUnsavedChangesDialog(false)
                setOpenDialogType(null)
                setIsDirty(false)
              }}
            >
              Yes, discard changes
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </InfluencerActionsContext.Provider>
  )
}

export const useInfluencerActions = () => {
  const context = useContext(InfluencerActionsContext)
  if (context === null) {
    throw new Error(
      "useInfluencerActions must be used within a InfluencerActionsProvider"
    )
  }
  return context
}
