import { useFormContext, useWatch } from "react-hook-form"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { cn } from "~/common/shadcn-utils"
import { Switch } from "~/ui/Switch"
import Text from "~/ui/typography"
import { SearchValues } from "../../schema"
import { Label } from "~/shadcn/ui/label"

export const SocialMediaFilter = ({
  enabledFieldName,
  hasRatesFieldName,
  icon,
  title,
  children,
}: {
  enabledFieldName:
    | "tikTokEnabled"
    | "instagramEnabled"
    | "youtubeEnabled"
    | "twitchEnabled"
  hasRatesFieldName:
    | "tikTokHasRates"
    | "instagramHasRates"
    | "youtubeHasRates"
    | "twitchHasRates"
  icon: string
  title: string
  children: React.ReactNode
}) => {
  const form = useFormContext<SearchValues>()

  const enabled = useWatch({
    control: form.control,
    name: enabledFieldName,
  })

  return (
    <div className="flex flex-row gap-4 mt-4 mb-8">
      <div className="pt-1">
        <FormField
          control={form.control}
          name={enabledFieldName}
          render={({ field }) => (
            <FormItem>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => field.onChange(checked)}
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className={cn("flex-grow", { "opacity-40": !enabled })}>
        <div className="flex flex-row items-center gap-2">
          <div>
            <img src={icon} alt={title} className="w-[18px]" />
          </div>
          <span className="flex-grow">{title}</span>
          <FormField
            control={form.control}
            name={hasRatesFieldName}
            render={({ field }) => (
              <div className="flex flex-row items-center gap-3">
                <Switch
                  id={hasRatesFieldName}
                  variant="green"
                  enabled={field.value || false}
                  setEnabled={(toggle) => {
                    field.onChange(toggle)
                  }}
                  disabled={!enabled}
                />
                <Label htmlFor={hasRatesFieldName}>
                  <Text
                    variant="body2"
                    className={cn({ "text-greenDark": field.value })}
                  >
                    With Rates
                  </Text>
                </Label>
              </div>
            )}
          />
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4">{children}</div>
      </div>
    </div>
  )
}
