import { gql } from "~/__generated__"

export const SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT = gql(`
  fragment SocialMediaFeedPricing on SocialMediaFeedPricing {
    id
    rateType
    rateFlatCents
    rateMinCents
    rateMaxCents
  }
`)

export const FULL_INFLUENCER_FRAGMENT = gql(`
  fragment FullInfluencer on Influencer {
    id
    visible
    name
    gender
    languages
    age
    exclusivity
    countryCode
    countryData {
      id
      name
      emojiFlag
    }
    state
    city
    agentName
    agentEmail
    agentPhone
    category
    creatorTypes
    collaborationTypes
    audienceCountryCode
    audienceCountryData {
      id
      name
    }
    audienceAge
    audienceGender
    profileImageThumbnailUrl
    notes
    deleted
    tags {
      id
      name
    }
    tikTokProfile {
      id
      active
      handle
      url
      followers
      averageLikes
      averageViews
      engagementRate
      notes
      videoPricing {
        ...SocialMediaFeedPricing
      }
      linkInBioPricing {
        ...SocialMediaFeedPricing
      }
    }
    instagramProfile {
      id
      active
      handle
      url
      followers
      averageLikes
      averageViews
      engagementRate
      notes
      photoPricing {
        ...SocialMediaFeedPricing
      }
      reelsPricing {
        ...SocialMediaFeedPricing
      }
      storyPricing {
        ...SocialMediaFeedPricing
      }
      linkInBioPricing {
        ...SocialMediaFeedPricing
      }
    }
    youtubeProfile {
      id
      active
      handle
      url
      subscribers
      averageVideoLikes
      averageVideoViews
      videoEngagementRate
      averageShortsLikes
      averageShortsViews
      shortsEngagementRate
      notes
      integrationPricing {
        ...SocialMediaFeedPricing
      }
      dedicationPricing {
        ...SocialMediaFeedPricing
      }
      shortsPricing {
        ...SocialMediaFeedPricing
      }
    }
    twitchProfile {
      id
      active
      handle
      url
      subscribers
      concurrentViewers
      averageStreamViews
      notes
      streamPricing {
        ...SocialMediaFeedPricing
      }
    }
    agency {
      id
      name
    }
  }
`)

export const FULL_INFLUENCER_QUERY_DOCUMENT = gql(`
  query Influencer($influencerId: ID!, $rawData: Boolean) {
    influencer(influencerId: $influencerId, rawData: $rawData) {
      ...FullInfluencer
    }
  }
`)

export const CREATE_INFLUENCER_MUTATION = gql(`
  mutation CreateInfluencer($input: InfluencerCreateInput!) {
    influencerCreate(input: $input) {
      influencer {
        ...FullInfluencer
      }
      currentUser {
        ...CurrentUser
      }
    }
  }
`)

export const UPDATE_INFLUENCER_MUTATION = gql(`
  mutation UpdateInfluencer($input: InfluencerUpdateInput!) {
    influencerUpdate(input: $input) {
      influencer {
        ...FullInfluencer
      }
      currentUser {
        ...CurrentUser
      }
    }
  }
`)

export const DELETE_INFLUENCER_MUTATION = gql(`
  mutation DeleteInfluencer($input: InfluencerDeleteInput!) {
    influencerDelete(input: $input) {
      influencer {
        ...FullInfluencer
      }
      currentUser {
        ...CurrentUser
      }
    }
  }
`)

export const CREATE_TAG_MUTATION = gql(`
  mutation CreateTag($input: TagCreateInput!) {
    tagCreate(input: $input) {
      tag {
        id
        name
      }
    }
  }
`)
